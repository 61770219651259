import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import Item from "../../components/Item";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)} auto`,
    padding: theme.spacing(2),
  },

  divMaterial: {
    // margin: 10, //`${theme.spacing(1)} auto`,
    padding: 10, // theme.spacing(1),
  },

  bigGrid: {
    flex: 1,
  },

  grid: {
    border: "1px solid #999999",
    borderRadius: 8,
    margin: 10,
    padding: 10,
    paddingTop: 20,
  },
  titulo: {
    fontSize: 16,
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  centrar: {
    justifyContent: "center",
    alignItems: "center",
  },

  droppable: {
    margin: 10,
  },
}));

const Task = (props) => {
  const isDragDisabled = props.material.idMaterial === "mat-1";

  const classes = useStyles();

  return (
    <Draggable
      key={props.material.idMaterial}
      draggableId={props.material.idMaterial}
      index={props.index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <Grid
          container
          style={{
            border: "1px solid lightgrey",
            borderRadius: "2px",
            padding: "8px",
            marginBottom: "8px",
            transition: "backgroundColor 0.2s ease",
            backgroundColor: (props) =>
              props.isDragDisabled
                ? "lightgrey"
                : props.isDragging
                ? "lightgreen"
                : "white",
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          isDragDisabled={isDragDisabled}
          spacing={2}
          className={classes.centrar}
        >
          <Grid item xs={10}>
            <Item
              texto={props.material.cantidad + ` x ` + props.material.nombre}
            />
          </Grid>
        </Grid>
      )}
    </Draggable>

    // <Draggable
    //   draggableId={props.task.id}
    //   index={props.index}
    //   isDragDisabled={isDragDisabled}
    // >
    //   {(provided, snapshot) => (
    //     <div
    //       style={{
    //         border: "1px solid lightgrey",
    //         borderRadius: "2px",
    //         padding: "8px",
    //         marginBottom: "8px",
    //         transition: "backgroundColor 0.2s ease",
    //         backgroundColor: (props) =>
    //           props.isDragDisabled
    //             ? "lightgrey"
    //             : props.isDragging
    //             ? "lightgreen"
    //             : "white",
    //       }}
    //       {...provided.draggableProps}
    //       {...provided.dragHandleProps}
    //       innerRef={provided.innerRef}
    //       isDragging={snapshot.isDragging}
    //       isDragDisabled={isDragDisabled}
    //     >
    //       {props.task.content}
    //     </div>
    //   )}
    // </Draggable>
  );
};

export default Task;
