// import { Typography } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";

const ItemPedido = (props) => {
  return (
    <Typography key={props.material.idMaterial} variant="subtitle1">
      {props.material.cantidad} x {props.material.material}
    </Typography>
  );
};

export default ItemPedido;
