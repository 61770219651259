import React from "react";
import clsx from "clsx";
import { createTheme, useTheme } from "@mui/material"; // "@mui/material/styles";
import Drawer from "@mui/material/Drawer"; // "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline"; //"@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar"; // "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar"; // "@mui/material/Toolbar";
import List from "@mui/material/List"; // "@mui/material/List";
import Typography from "@mui/material/Typography"; // "@mui/material/Typography";
import Divider from "@mui/material/Divider"; // "@mui/material/Divider";
import IconButton from "@mui/material/IconButton"; // "@mui/material/IconButton";
import MenuIcon from "@mui/material/Menu"; // "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"; // "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight"; // @mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem"; // "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon"; // "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText"; // "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"; // "@mui/icons-material/HomeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined"; // "@mui/icons-material/ShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"; // "@mui/icons-material/LocalShippingOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"; // "@mui/icons-material/DescriptionOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined"; // "@mui/icons-material/ExitToAppOutlined";
import AddIcon from "@mui/icons-material/Add"; // "@mui/icons-material/Add";

import { Routes, Route, Link, useNavigate } from "react-router-dom";

import PageMateriales from "../pages/materiales/PageMateriales";
import PageDashboard from "../pages/dashboard/PageDashboard";
import NoPage from "./NoPage";
import PageSupervisarPedido from "../pages/pedidos/PageSupervisarPedido";
import PageAltaPedido from "../pages/pedidos/PageAltaPedido";
import PageVolquetes from "../pages/volquetes/PageVolquetes";

import { makeStyles } from "@mui/styles"; // "@mui/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  titulo: {
    flexGrow: 1,
  },
  texto: {
    color: "#323232",
  },
  textoSeleccionado: {
    color: "#5052ff",
  },
  noDecoration: {
    textDecoration: "none",
  },
}));

const iconoApropiado = (text) => {
  switch (text) {
    case "Pedidos":
      return (
        <Link to="/dashboard">
          <HomeOutlinedIcon />
        </Link>
      );

    case "Materiales":
      return <ShoppingCartOutlinedIcon />;

    case "Volquetes":
      return <LocalShippingOutlinedIcon />;

    case "Documentación":
      return <DescriptionOutlinedIcon />;

    default:
      break;
  }
};

// const useStyles = createTheme((theme) => ({
//   titulo: {
//     flexGrow: 1,
//   },
//   texto: {
//     color: "#323232",
//   },
//   textoSeleccionado: {
//     color: "#5052ff",
//   },
//   noDecoration: {
//     textDecoration: "none",
//   },
// }));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAltaPedido = () => {
    alert("ALTA PEDIDO");
  };

  let navigate = useNavigate();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.titulo} noWrap>
            Administración de pedidos
          </Typography>
          <IconButton
            color="inherit"
            // onClick={handleAltaPedido}
            aria-label="open alta"
            size="large"
          >
            <AddIcon fontSize="large" onClick={() => navigate("/altaPedido")} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button key="dashboard">
            <Link to="/dashboard" className={classes.noDecoration}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
            </Link>
            <Link to="/dashboard" className={classes.noDecoration}>
              <ListItemText
                primary={
                  <Typography style={{ color: "#323232" }}>Pedidos</Typography>
                }
              />
            </Link>
          </ListItem>

          <ListItem button key="materiales">
            <Link to="/materiales" className={classes.noDecoration}>
              <ListItemIcon>
                <ShoppingCartOutlinedIcon />
              </ListItemIcon>
            </Link>
            <Link to="/materiales" className={classes.noDecoration}>
              <ListItemText
                primary={
                  <Typography style={{ color: "#323232" }}>
                    Materiales
                  </Typography>
                }
              />
            </Link>
          </ListItem>

          <ListItem button key="volquetes" className={classes.noDecoration}>
            <Link to="/volquetes" className={classes.noDecoration}>
              <ListItemIcon>
                <LocalShippingOutlinedIcon />
              </ListItemIcon>
            </Link>
            <Link to="/volquetes" className={classes.noDecoration}>
              <ListItemText
                primary={
                  <Typography style={{ color: "#323232" }}>
                    Volquetes
                  </Typography>
                }
              />
            </Link>
          </ListItem>

          <ListItem button key="documentacion">
            <Link to="/documentacion" className={classes.noDecoration}>
              <ListItemIcon>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
            </Link>
            <Link to="/documentacion" className={classes.noDecoration}>
              <ListItemText
                disableTypography
                primary={
                  <Typography style={{ color: "#323232" }}>
                    Documentación
                  </Typography>
                }
              />
            </Link>
          </ListItem>

          {/* {["Pedidos", "Materiales", "Volquetes", "Documentación"].map(
              (text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>{iconoApropiado(text)}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              )
            )} */}
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        <Routes>
          <Route index path="/materiales" element={<PageMateriales />} />
          <Route path="/dashboard" element={<PageDashboard />} />
          <Route path="/supervisarPedido" element={<PageSupervisarPedido />} />
          <Route path="/altaPedido" element={<PageAltaPedido />} />
          <Route path="/volquetes" element={<PageVolquetes />} />
          <Route path="/" element={<PageDashboard />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </main>
    </div>
  );
}
