import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";

const WhatsappEnviarMensaje = ({ numeroTel, mensaje }) => {
  const URL = "https://wa.me";

  //   let num = "5491158482073";
  numeroTel = numeroTel.replace(/[^\w\s]/gi, "").replace(/ /g, "");

  let url = `${URL}/${numeroTel}`;

  //   let message = "HOLA MUNDO";

  if (mensaje) {
    url += `?text=${encodeURI(mensaje)}`;
  }

  return (
    <Button
      variant="contained"
      endIcon={<SendIcon />}
      onClick={(e) => {
        window.open(url);
      }}
    >
      Send
    </Button>
  );
};

export default WhatsappEnviarMensaje;
