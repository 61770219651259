import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

// import { makeStyles, Paper } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flexGrow: 1,
  //     overflow: "hidden",
  //     padding: theme.spacing(0, 3),
  //   },
  divCard: {
    margin: `${theme.spacing(1)} auto`,
    padding: theme.spacing(1),
    border: "1px solid #999999",
    borderRadius: 8,
  },
}));

const Item = (props) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.divCard}>
      {props.texto}
    </Paper>
  );
};

export default Item;
