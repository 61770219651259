import { Grid } from "@mui/material";
import React, { useState } from "react";

import { DragDropContext } from "react-beautiful-dnd";
import Boton from "../../components/Boton";
// import styled from "styled-components";

import Column from "./Column";

const PageSupervisarPedido = () => {
  const retMateriales = [
    {
      idMaterial: 1, // `item-1`,
      material: "Cemento",
      cantidad: 3,
    },
    {
      idMaterial: 2,
      material: "Cal",
      cantidad: 2,
    },
    {
      idMaterial: 3,
      material: "Yeso",
      cantidad: 5,
    },
  ];

  const retProveedores = [
    {
      idProveedor: 1,
      proveedor: "Corralón Mortola",
      cateogoria: "Corralones",
    },
    {
      idProveedor: 2,
      proveedor: "Pinturería M&M",
      cateogoria: "Pinturerías",
    },
  ];

  const initialData = {
    materiales: {
      "1": { idMaterial: "1", nombre: "Cemento (x bolsa)", cantidad: 10 },
      "2": { idMaterial: "2", nombre: "Cal (x pallet)", cantidad: 2 },
      "3": { idMaterial: "3", nombre: "Yeso (x pallet)", cantidad: 4 },
      "4": { idMaterial: "4", nombre: "Arena (x camión)", cantidad: 1 },
    },
    columns: {
      "0": {
        id: "0",
        titulo: "PEDIDO",
        matIds: ["1", "2", "3", "4"],
      },
      "1": {
        id: "1",
        titulo: "Corralón Mortola",
        matIds: [],
      },
      "2": {
        id: "2",
        titulo: "Pinturarías M&M",
        matIds: [],
      },
    },
    // Facilitate reordering of the columns
    columnOrder: ["0", "1", "2"],
  };

  const [state, setState] = useState(initialData);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      const newMatIds = Array.from(start.matIds);
      newMatIds.splice(source.index, 1);
      newMatIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        matIds: newMatIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };

      setState(newState);
      return;
    }

    // Moving from one list to another
    const startMatIds = Array.from(start.matIds);
    startMatIds.splice(source.index, 1);
    const newStart = {
      ...start,
      matIds: startMatIds,
    };

    const finishMatIds = Array.from(finish.matIds);
    finishMatIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      matIds: finishMatIds,
    };

    console.log(newStart);
    console.log(newFinish);

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setState(newState);
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={10}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div style={{ display: "flex" }}>
            {state.columnOrder.map((columnId) => {
              const column = state.columns[columnId];
              const materiales = column.matIds.map(
                (matId) => state.materiales[matId]
              );

              return (
                <Column
                  key={column.id}
                  column={column}
                  materiales={materiales}
                />
              );
            })}
          </div>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

export default PageSupervisarPedido;
