import React from "react";
// import styled from "styled-components";
import Task from "./Task";
import { Droppable } from "react-beautiful-dnd";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import WhatsappEnviarMensaje from "../../components/WhatsappEnviarMensaje";

const useStyles = makeStyles((theme) => ({
  bigGrid: {
    flex: 1,
  },

  grid: {
    border: "1px solid #999999",
    borderRadius: 8,
    margin: 10,
    padding: 10,
    paddingTop: 20,
  },
  titulo: {
    fontSize: 16,
    // flex: 1,
    // justifyContent: "center",
    textAlign: "center",
    // border: "1px solid",
    // alignItems: "flex-end",
  },
  centrar: {
    justifyContent: "center",
    alignItems: "center",
  },

  droppable: {
    margin: 10,
  },
}));

const Column = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.bigGrid}>
      <Droppable
        droppableId={props.column.id}
        type="TASK"
        className={classes.droppable}
      >
        {(provided, snapshot) => (
          // {(droppableProvided) => (
          <Grid
            item
            style={{
              padding: "8px",
              transition: "background-color 0.2s ease",
              backgroundColor: (props) =>
                props.isDraggingOver ? "skyblue" : "white",
              flexGrow: "1",
              minHeight: "100px",
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            // spacing={2}
            className={classes.grid}
            sm={11}
          >
            <Grid item className={classes.titulo}>
              {props.column.titulo}
            </Grid>

            {props.materiales.map((material, index) => (
              <Task
                key={material.idMaterial}
                material={material}
                index={index}
              />
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>

      <Box sx={{ flexGrow: 1, paddingTop: 2 }}>
        <Grid item xs={11} style={{ textAlign: "right" }}>
          {/* <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={(e) => {
              window.open(url);
            }}
          >
            Send
          </Button> */}

          <WhatsappEnviarMensaje
            numeroTel="5491158482073"
            mensaje="Acá va el pedido al proveedor"
          />

          {/* <ReactWhatsapp
            number="5491158482073"
            message="Acá va el pedido al proveedor."
            element={() => <Boton />}
            onClick={() => alert("PASA")}
          /> */}
        </Grid>
      </Box>
    </Grid>
  );
};

export default Column;
