import React from "react";

import {
  Paper,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

// import Paper from "@mui/material/Paper";
// import { Box, Divider, Grid, IconButton } from "@mui/material";
// import { makeStyles } from "@mui/material/styles";
// import Avatar from "@mui/material/Avatar";
// import Typography from "@mui/material/Typography";
import PedidosItem from "./PedidosItem";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)} auto`,
    padding: theme.spacing(2),
  },
  marginBottom10: {
    marginBottom: "10px",
  },
  fecha: {
    fontSize: 14,
    marginTop: 5,
  },
  arrow: {
    // flexGrow: 1,
    border: "1px solid",
    // flex: 1,
    justifyContent: "flex-end",
  },
}));

const Pedido = (props) => {
  const classes = useStyles();

  let navigate = useNavigate();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid
        container
        // wrap="nowrap"
        spacing={2}
        // direction="row"
        // justifyContent="flex-start"
        // alignItems="flex-start"
      >
        {/* <Grid item>
          <Avatar>{props.pedido.idPedido}</Avatar>
        </Grid> */}
        <Grid item xs>
          <Typography variant="h6">{props.pedido.titulo}</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.fecha}>
            {props.pedido.fecha
              .split(" ")[0]
              .split("-")
              .reverse()
              .join("/")}
          </Typography>
        </Grid>

        <Box display="flex" justifyContent="flex-end">
          <IconButton size="large">
            <ArrowForwardIosOutlinedIcon
              onClick={() => navigate("/supervisarPedido")}
            />
          </IconButton>
        </Box>

        <Grid item xs={12}>
          <Divider className={classes.marginBottom10} />

          {props.pedido.lista.map((item) => {
            return <PedidosItem key={item.idMaterial} material={item} />;
          })}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Pedido;
