import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter } from "react-router-dom";

import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@mui/material/styles";

const theme = createMuiTheme();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navbar />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
