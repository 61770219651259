import React from "react";
// import Titulo from "../../components/Titulo";
import PedidosLista from "../pedidos/PedidosLista";

const PageDashboard = () => {
  return (
    <div>
      {/* <Titulo texto="Pedidos a supervisar" /> */}
      <PedidosLista estado="A_SUPERVISAR" texto="Pedidos a supervisar" />

      {/* <Titulo texto="Pedidos pendientes" /> */}
      <PedidosLista estado="PENDIENTES" texto="Pedidos pendientes" />

      {/* <Titulo texto="Pedidos entregados" /> */}
      <PedidosLista estado="ENTREGADOS" texto="Pedidos entregados" />
    </div>
  );
};

export default PageDashboard;
