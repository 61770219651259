import React, { useState, useEffect } from "react";
// import { Grid } from "@mui/material";

import Pedido from "./Pedido";
import Titulo from "../../components/Titulo";
import Grid from "@mui/material/Grid";

const PedidosLista = (props) => {
  // const pedidos = [
  //   {
  //     idPedido: 1,
  //     titulo: "Pedido #1",
  //     fecha: "2022-06-16 15:44:18",
  //     lista: [
  //       { idMaterial: 1, material: "Material #1", cantidad: 5 },
  //       { idMaterial: 2, material: "Material #2", cantidad: 2 },
  //     ],
  //   },
  //   {
  //     idPedido: 2,
  //     titulo: "Pedido #2",
  //     fecha: "2022-06-16 15:45:20",
  //     lista: [
  //       { idMaterial: 17, material: "Material #1", cantidad: 5 },
  //       { idMaterial: 12, material: "Material #2", cantidad: 2 },
  //       { idMaterial: 11, material: "Material #3", cantidad: 3 },
  //     ],
  //   },
  // ];

  const [pedidos, setPedidos] = useState([]);

  const obtenerPedidos = async () => {
    // console.log("pedido: " + JSON.stringify(props.pedido));

    const API_ENDPOINT = `https://materiales.bimtracker.com/backend/api_pedidos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // operacion: "POST_PEDIDOS_PENDIENTES",
        operacion: "POST_MOSTRAR_PEDIDOS",
        estado: props.estado,
        idUsuario: 2,
        idObra: 1,
      }),
    });
    const respuesta = await datos.json();

    if (respuesta.result.pedidos) {
      setPedidos(respuesta.result.pedidos);
    } else {
      setPedidos([]);
    }

    // alert(respuesta.result);

    console.log(respuesta.result.pedidos);
  };

  useEffect(() => {
    obtenerPedidos();
  }, []);

  return (
    <div>
      {pedidos.length > 0 ? <Titulo texto={props.texto} /> : null}

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {pedidos.map((ped) => {
          return <Pedido key={ped.idPedido} pedido={ped} />;
        })}
      </Grid>
    </div>
  );
};

export default PedidosLista;
